<template>
  <div v-if="shouldDisplayWrapper" class="flex flex-col gap-3">
    <slot
      v-if="shouldDisplayInstallments"
      :is-loading="pending"
      name="installments"
      :simulation="data"
    >
      <div v-for="line in lines" :key="JSON.stringify(line)" class="flex gap-3">
        <LoadableText
          class="body-2-bold min-w-96"
          :loading="pending"
          skeleton-width="4rem"
        >
          {{ line.date }}
        </LoadableText>

        <LoadableText class="body-2" :loading="pending" skeleton-width="10rem">
          {{ line.text }}
        </LoadableText>
      </div>

      <div>
        <LoadableText
          class="body-2-bold mt-3"
          :loading="pending"
          skeleton-width="6rem"
        >
          {{ total }}
        </LoadableText>

        <LoadableText class="body-2" :loading="pending" skeleton-width="12rem">
          {{ cost }}
        </LoadableText>
      </div>
    </slot>

    <slot v-if="shouldDisplayFallback" name="fallback" />
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots, watch } from 'vue'

import { type Price, paymentAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { formatDateToYYYYMMDD } from '@backmarket/utils/date/formatDateToYYYYMMDD'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type {
  PaymentMethodHardcodedConfig,
  PaymentMethodWithInstallmentHardcodedConfig,
} from '../../../methods/config/methods.type'
import { paymentMethodConfig } from '../../../methods/helpers/methods'
import LoadableText from '../LoadableText'

import translations from './InstallmentSimulation.translations'

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
}

const i18n = useI18n()

const props = defineProps<{
  paymentMethod: paymentAPI.PaymentMethod
  basePrice: Price
}>()
const slots = useSlots()

const amountToPrice = (amount: number) => ({
  amount: String(amount || 0),
  currency: props.basePrice.currency,
})

function isPaymentMethodWithInstallmentHardcodedConfig(
  config: PaymentMethodHardcodedConfig,
): config is PaymentMethodWithInstallmentHardcodedConfig {
  return 'installmentCount' in config
}

const EMPTY_INSTALLMENT_CONFIG: Pick<
  PaymentMethodWithInstallmentHardcodedConfig,
  'installmentCount' | 'ratesByCountry'
> = {
  installmentCount: 0,
}

const installmentConfig = computed(() => {
  if (isEmpty(props.paymentMethod)) {
    return EMPTY_INSTALLMENT_CONFIG
  }

  const config = paymentMethodConfig(props.paymentMethod.bmCode)

  return isPaymentMethodWithInstallmentHardcodedConfig(config)
    ? config
    : EMPTY_INSTALLMENT_CONFIG
})

const installmentCount = computed(
  () => installmentConfig.value.installmentCount,
)

const requiresPayment = computed(() => parseFloat(props.basePrice.amount) > 0)
const hasInstallments = computed(() => installmentCount.value > 0)
const shouldDisplayInstallments = computed(
  () => hasInstallments.value && requiresPayment.value,
)
const shouldDisplayFallback = computed(
  () => !shouldDisplayInstallments.value && !!slots.fallback,
)
const shouldDisplayWrapper = computed(
  () => shouldDisplayInstallments.value || shouldDisplayFallback.value,
)

const queryParams = computed(() => ({
  payment_method: props.paymentMethod.bmCode,
  amount: props.basePrice.amount,
  nb_installments: String(installmentCount.value),
  country_code: useMarketplace().market.countryCode,
}))
const { data, pending, execute } = useHttpFetch(paymentAPI.getLoanSimulation, {
  queryParams,
  immediate: false,
  watch: false,
})

watch(
  queryParams,
  () => {
    if (hasInstallments.value) {
      void execute()
    }
  },
  { immediate: true },
)

const lines = computed(() => {
  // Skeleton lines
  if (pending.value) {
    return Array.from({ length: installmentCount.value }).map((_, index) => ({
      key: index,
      date: '',
      text: '',
    }))
  }

  if (!data.value) {
    return []
  }

  return [
    {
      date: i18n.date(new Date(), DATE_FORMAT),
      text: i18n(translations.downAmount, {
        amount: i18n.price(amountToPrice(data.value.downAmount)),
      }),
    },
    ...data.value.installments.map((installment, index) => ({
      date: i18n.date(
        new Date(formatDateToYYYYMMDD(installment.collectionDate)),
        DATE_FORMAT,
      ),
      text: i18n(translations.wording, {
        amount: i18n.price(amountToPrice(installment.amount)),
        number: index + 1,
      }),
    })),
  ]
})

const total = computed(() =>
  pending.value || data.value === null
    ? ''
    : i18n(translations.total, {
        total: i18n.price(amountToPrice(data.value.totalAmount)),
      }),
)

const marketplace = useMarketplace()

const cost = computed(() =>
  pending.value || data.value === null
    ? ''
    : i18n(translations.cost, {
        cost: i18n.price(amountToPrice(data.value.cost)),
        rate:
          installmentConfig.value.ratesByCountry?.[
            marketplace.market.countryCode
          ] ?? '-',
      }),
)
</script>
