<template>
  <div class="flex flex-wrap items-center gap-4">
    <RevIllustration
      v-for="({ icon, label }, id) in icons"
      :key="id"
      :alt="label"
      :height="20"
      :src="icon"
      :width="36"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import type { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment'
import { type I18nValues } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevIllustration } from '@ds/components/Illustration'

import { usePaymentMethodsWithFallback } from '../../composables/usePaymentMethodsWithFallback'
import {
  isPaymentMethodEnabled,
  paymentMethodConfig,
  paymentMethodIcon,
  paymentNetworkConfig,
  paymentNetworkIcon,
} from '../../helpers/methods'

const i18n = useI18n()

export type PaymentIconType = 'methods' | 'networks'

const props = withDefaults(
  defineProps<{
    methods?: paymentAPI.PaymentMethod[] | null
    showDisabledMethods?: boolean
    type?: PaymentIconType
  }>(),
  {
    methods: undefined,
    showDisabledMethods: false,
    type: 'networks',
  },
)

const paymentMethods = usePaymentMethodsWithFallback(() => props.methods)

const displayedMethods = computed(() => {
  return props.showDisabledMethods
    ? paymentMethods.value
    : paymentMethods.value.filter((method) => isPaymentMethodEnabled(method))
})

const methodIcons = computed(() =>
  displayedMethods.value.reduce((acc, { bmCode }) => {
    if (!bmCode) {
      return acc
    }
    const { name, ...config } = paymentMethodConfig(bmCode)
    const icon = paymentMethodIcon(bmCode)

    return isEmpty(icon)
      ? acc
      : {
          ...acc,
          [bmCode]: {
            icon,
            label: i18n(name, config as unknown as I18nValues),
          },
        }
  }, {}),
)

const getNetworkIcons = (networks: PaymentNetwork[] = []) => {
  return networks.reduce((acc, networkId) => {
    const icon = paymentNetworkIcon(networkId)
    const { name } = paymentNetworkConfig(networkId)

    return isEmpty(icon)
      ? acc
      : {
          ...acc,
          [networkId]: {
            icon,
            label: i18n(name),
          },
        }
  }, {})
}

const networkIcons = computed(() =>
  displayedMethods.value.reduce(
    (acc, { networks }) => ({
      ...acc,
      ...getNetworkIcons(networks),
    }),
    {},
  ),
)

const icons = computed(() => {
  const iconTypes: Record<PaymentIconType, object> = {
    methods: methodIcons.value,
    networks: networkIcons.value,
  }

  return iconTypes[props.type] || {}
})
</script>
