import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import { type Price, paymentAPI } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { filterABTestedPaymentMethods } from '../helpers/filterABTestedPaymentMethods'

/**
 * Returns payment methods for the current country that correspond to the given listings, bag price ...
 * Prefer the use of the cached version `useMarketPaymentMethods` when possible
 *
 * @example
 * const { data: paymentMethods } = await useMarketPaymentMethodsFor({
 *  listings: ['123', '456'],
 *  bagPrice: { amount: '99.99', currency: 'EUR' },
 * })
 */
export const useMarketPaymentMethodsFor = ({
  listings,
  bagPrice,
  hasInsurance,
  tokenization,
}: {
  listings?: MaybeRefOrGetter<string[]>
  bagPrice?: MaybeRefOrGetter<Price | undefined>
  hasInsurance?: MaybeRefOrGetter<boolean>

  /**
   * When true, only the payment methods that support tokenization will be returned
   */
  tokenization?: MaybeRefOrGetter<boolean>
}) => {
  const { market } = useMarketplace()

  const experiments = useExperiments()

  return useHttpFetch(paymentAPI.getMethods, {
    queryParams: {
      country_code: market.countryCode,
      listings: computed(() => toValue(listings)?.join(',')),
      bag_price: computed(() => toValue(bagPrice)?.amount),
      insurance_subscription: hasInsurance,
      features: computed(() =>
        toValue(tokenization) ? 'tokenizable' : undefined,
      ),
    },
    transform: (data) =>
      filterABTestedPaymentMethods(experiments, data.results),
  })
}
